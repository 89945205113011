<script>
	import { _, locale } from 'svelte-i18n';
	import { data } from '../../stores';
	import MailIcon from '../icon/MailIcon.svelte';
	import VerifyEmail from '../VerifyEmail.svelte';
	import LoginProvider from '../LoginProvider.svelte';
	import ManagedProvider from '../ManagedProvider.svelte';
	import HelpButton from '../button/HelpButton.svelte';
	import LoginRequiredModal from '../modal/LoginRequiredModal.svelte';

	export let heading = '';
	export let label = '';
	export let accountToUse = undefined;
	export let accountSelected = undefined;
	export let authnWithPreferred = false;
	export let authnWithManaged = false;
	export let accounts = [];
	export let showUseDifferentManaged = true;
	export let continueWithProvider = () => {};
	export let contactLoginSuccess = () => {};
	export let handleContactError = () => {};
	export let handleManagedEmailSuccess = () => {};
	export let continueWithEthereumExtension = () => {};

	let useDifferentManaged = false;
	let authnWithPreferredModal = false;
	let authnWithManagedModal = false;

	//auto-expand use different managed dropdown
	if ($data?.useDifferentManaged) {
		useDifferentManaged = true;
	}
</script>

<section data-test="managed-providers-container">
	{#if authnWithPreferredModal}
		<LoginRequiredModal
			{contactLoginSuccess}
			{handleContactError}
			{continueWithEthereumExtension}
			{continueWithProvider}
			label={$_('To use a managed account')}
			providers={$data?.preferred}
			accountToUse="managed"
			on:close={() => (authnWithPreferredModal = false)}
			useDifferentManaged={true}
		/>
	{:else if authnWithManagedModal}
		<LoginRequiredModal
			{contactLoginSuccess}
			{handleContactError}
			{continueWithEthereumExtension}
			{continueWithProvider}
			label={$_('To add a different managed account')}
			providers={$data?.managed}
			accountToUse="managed"
			on:close={() => (authnWithManagedModal = false)}
			useDifferentManaged={true}
		/>
	{/if}

	{#if heading}
		<h1 class="relative flex items-center justify-center text-lg font-medium mb-6">
			{heading}
		</h1>
	{/if}

	{#if label}
		<h2
			class="block text-sm font-medium {$locale && $locale.startsWith('ar')
				? 'text-right'
				: 'text-left'}"
		>
			{@html label}
		</h2>
	{/if}

	<section class="mt-2">
		{#if Array.isArray(accounts)}
			<div class="space-y-2">
				{#each accounts as managed}
					{#if managed.slug === 'email'}
						<div class="relative h-auto btn-border rounded-md hover-none px-4">
							<button class="h-16 w-full flex items-center justify-start">
								<MailIcon size="lg" />
								<div
									class="flex flex-col {$locale && $locale.startsWith('ar')
										? 'text-right mr-4'
										: 'text-left ml-4'}"
								>
									<span>
										{$_('Continue with Email')}
									</span>
									<span>{managed.login_hint}</span>
								</div>
							</button>
							<div class="pb-3 pt-1">
								<VerifyEmail
									{accountToUse}
									{accountSelected}
									email={managed.login_hint}
									login
									disabled
									on:success={contactLoginSuccess}
									on:error={handleContactError}
								/>
							</div>
						</div>
					{:else}
						<LoginProvider
							login={true}
							managed={true}
							on:click={() =>
								continueWithProvider({
									slug: managed.slug,
									body: {
										login_hint: managed.login_hint,
										accountToUse,
										accountSelected
									},
									server: managed.slug === 'mastodon' ? managed.mastodonServer : null
								})}
							provider={managed}
							prefix="Continue with"
						/>
					{/if}
				{/each}
			</div>
		{/if}

		{#if showUseDifferentManaged}
			<div class="flex justify-between items-center my-2">
				<button
					on:click={() => {
						if (authnWithPreferred) {
							authnWithPreferredModal = true;
						} else if (authnWithManaged) {
							authnWithManagedModal = true;
						} else {
							useDifferentManaged = !useDifferentManaged;
						}
					}}
					class="text-sm focus:underline hover:underline inline-flex items-center space-x-1 {$locale &&
					$locale.startsWith('ar')
						? 'text-left'
						: 'text-right'}"
				>
					{#if accounts?.length}
						{useDifferentManaged
							? $_('Hide other managed account')
							: $_('Use a different managed account')}
					{:else}
						<span
							>{useDifferentManaged
								? $_('Hide other managed account')
								: $_('Use a managed account')}</span
						>
						<HelpButton
							ariaLabel="Help"
							content={$_('Accounts managed by your employer, school, or other organization')}
							placement="bottom"
						/>
					{/if}
				</button>
			</div>
		{/if}
	</section>

	{#if useDifferentManaged}
		<ManagedProvider
			login={true}
			{accountSelected}
			css="mt-3"
			on:managedEmailSuccess={handleManagedEmailSuccess}
		/>
	{/if}
</section>
