<script>
	// import { onMount } from 'svelte';
	import { _, locale } from 'svelte-i18n';
	import { data } from '../../stores';
	import MailIcon from '../icon/MailIcon.svelte';
	import VerifyEmail from '../VerifyEmail.svelte';
	import VerifyPhone from '../VerifyPhone.svelte';
	import PhoneIcon from '../icon/PhoneIcon.svelte';
	import LoginProvider from '../LoginProvider.svelte';
	import tippy from 'tippy.js';
	import RecoveryProviders from './RecoveryProviders.svelte';
	// import { promptForPasskey } from '../../utils/helper.js';
	// import { startAuthentication } from '@simplewebauthn/browser';
	// import { getLoginChallenge, postLoginChallenge } from '../../passkey.js';
	// import PasskeyNotFoundModal from '../modal/PasskeyNotFoundModal.svelte';

	export let heading = '';
	export let label = '';
	export let accountSelected = undefined;
	export let accountToUse = undefined;
	export let accounts = [];
	export let showOtherWaysToLogin = true;
	export let contactLoginSuccess = () => {};
	export let handleContactError = () => {};
	export let continueWithEthereumExtension = () => {};
	export let continueWithProvider = () => {};
	export let showRecoveries = false;

	// let showPasskeyLogin = false;
	// let showPasskeyNotFoundModal = false;

	$: loggedInSubject = $data?.recovery?.find((i) => i.id === $data?.subjects[0]);

	// onMount(async () => {
	// 	showPasskeyLogin = await promptForPasskey();
	// });

	// let passkeyLoginAjax = false;
	// async function passkeyLogin() {
	// 	try {
	// 		passkeyLoginAjax = true;
	// 		const challenge = await getLoginChallenge();
	// 		const signedChallenge = await startAuthentication(challenge);
	// 		const webAuthnLoginRes = await postLoginChallenge(signedChallenge);
	// 		if (!webAuthnLoginRes?.verified) throw webAuthnLoginRes;
	// 		if (window.isWalletAuthorizeApp) {
	// 			$data = await getConsent();
	// 		} else {
	// 			$data = await getProfile();
	// 		}
	// 		return replaceRoute('/');
	// 	} catch (err) {
	// 		if (err.status) {
	// 			//no passkeys found
	// 			showPasskeyNotFoundModal = true;
	// 			console.error(err);
	// 		} else {
	// 			$notification = {
	// 				text: 'Authentication cancelled',
	// 				type: 'error'
	// 			};
	// 		}
	// 	} finally {
	// 		passkeyLoginAjax = false;
	// 	}
	// }
</script>

<!-- {#if showPasskeyNotFoundModal}
	<PasskeyNotFoundModal on:close={() => (showPasskeyNotFoundModal = false)} />
{/if} -->

<section data-test="preferred-providers-container">
	{#if heading}
		<h1 class="relative flex items-center justify-center text-lg font-medium mb-6">
			{heading}
		</h1>
	{/if}

	{#if label}
		<div class="flex justify-between items-center mb-2">
			<h2
				class="block text-sm font-medium {$locale && $locale.startsWith('ar')
					? 'text-right'
					: 'text-left'}"
			>
				{@html label}
			</h2>
		</div>
	{/if}
	{#if Array.isArray(accounts)}
		<div class="space-y-2">
			{#each accounts as preferred}
				{#if preferred.slug === 'email'}
					<div class="relative h-auto btn-border rounded-md hover-none px-4">
						<button class="h-16 w-full flex items-center justify-start">
							<MailIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'text-right mr-4'
									: 'text-left ml-4'}"
							>
								<span>
									{$_('Continue with Email')}
								</span>
								<span>{preferred.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyEmail
								{accountToUse}
								{accountSelected}
								email={preferred.login_hint}
								login
								disabled
								on:success={contactLoginSuccess}
								on:error={handleContactError}
							/>
						</div>
					</div>
				{:else if preferred.slug === 'phone'}
					<div class="relative h-auto btn-border rounded-md hover-none px-4">
						<button class="h-16 w-full flex items-center justify-start">
							<PhoneIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'text-right mr-4'
									: 'text-left ml-4'}"
							>
								<span>
									{$_('Continue with Phone')}
								</span>
								<span>{preferred.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyPhone
								{accountToUse}
								{accountSelected}
								phone={preferred.login_hint}
								login
								disabled
								on:success={contactLoginSuccess}
								on:error={handleContactError}
							/>
						</div>
					</div>
				{:else if preferred.slug === 'ethereum'}
					{#if window.ethereum}
						<div class="relative h-auto btn-border rounded-md hover-none p-2 space-y-2">
							<span class="mb-3 block text-left"
								>{preferred.wallet?.name || 'Ethereum'}
								{#if preferred.wallet?.display}
									<span use:tippy={{ content: preferred.login_hint, placement: 'top' }}>
										({preferred.wallet?.display})
									</span>
								{/if}
							</span>
							<LoginProvider
								on:ethereum={() =>
									continueWithEthereumExtension({ info: preferred, accountSelected, accountToUse })}
								provider={preferred}
								hideusername
								prefix="Continue with"
							/>
							<!-- Note: Uncomment to show WalletConnect -->
							<!-- <button
								class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
								on:click={() => continueWithWalletConnect({info: preferred})}
							>
								<div class="flex items-center gap-x-4">
									<img
										src="https://cdn.hello.coop/images/walletconnect.svg"
										alt="WalletConnect"
										class="w-4.5 max-h-[18px]"
									/>
									<span class="block text-left">
										{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
									</span>
								</div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
								</svg>
							</button> -->
						</div>
					{/if}
				{:else}
					<LoginProvider
						login={true}
						on:click={() =>
							continueWithProvider({
								slug: preferred.slug,
								body: {
									login_hint: preferred.login_hint,
									accountToUse,
									accountSelected
								},
								server: preferred.slug === 'mastodon' ? preferred.mastodonServer : null,
								preferred: true
							})}
						provider={preferred}
						prefix="Continue with"
					/>
				{/if}
			{/each}
		</div>
	{/if}

	<!-- TBD Figure out passkey login later -->
	<!-- {#if showPasskeyLogin && $data?.hasPasskey}
		<section class="mt-2">
			<LoginProvider
				on:click={passkeyLogin}
				loading={passkeyLoginAjax}
				disabled={passkeyLoginAjax}
				provider={{ slug: 'passkey' }}
				label="Continue with Hellō Passkey"
			/>
		</section>
	{/if} -->

	{#if showOtherWaysToLogin}
		{#if $data.recovery?.length > 1 && !loggedInSubject}
			<div class="flex justify-between items-center my-2">
				<button
					on:click={() => (showRecoveries = !showRecoveries)}
					class="text-sm focus:underline hover:underline {$locale && $locale.startsWith('ar')
						? 'text-left'
						: 'text-right'}"
				>
					{showRecoveries ? $_('Hide other ways to log in') : $_('Show other ways to log in')}
				</button>
			</div>
		{/if}
	{/if}

	{#if showRecoveries}
		<RecoveryProviders
			{continueWithProvider}
			{continueWithEthereumExtension}
			{contactLoginSuccess}
			{handleContactError}
		/>
	{/if}
</section>
