<script>
	import { _, locale } from 'svelte-i18n';
	import { data } from '../../stores';
	import MailIcon from '../icon/MailIcon.svelte';
	import VerifyEmail from '../VerifyEmail.svelte';
	import VerifyPhone from '../VerifyPhone.svelte';
	import LoginProvider from '../LoginProvider.svelte';
	import tippy from 'tippy.js';
	import PhoneIcon from '../icon/PhoneIcon.svelte';

	export let continueWithProvider = () => {};
	export let continueWithEthereumExtension = () => {};
	export let contactLoginSuccess = () => {};
	export let handleContactError = () => {};
</script>

<section
	data-test="recovery-providers-container"
	class="bg-[#DCDCDC] dark:bg-[#505050] -mx-2 p-2 rounded-md mt-3"
>
	<h2
		class="{$locale && $locale.startsWith('ar')
			? 'text-right'
			: 'text-left'} block text-sm font-medium mb-2"
	>
		{$_('Or log in with two recovery providers')}
	</h2>

	<div class="space-y-2">
		{#each $data.recovery as recovery}
			{#if recovery.slug === 'email'}
				<div class="relative h-auto btn-border rounded-md hover-none px-4">
					<button class="h-16 w-full flex items-center justify-start">
						<MailIcon size="lg" />
						<div
							class="flex flex-col {$locale && $locale.startsWith('ar')
								? 'text-right mr-4'
								: 'text-left ml-4'}"
						>
							<span>
								{$_('Continue with Email')}
							</span>
							<span>{recovery.login_hint}</span>
						</div>
					</button>
					<div class="pb-4 pt-1">
						<VerifyEmail
							email={recovery.login_hint}
							accountToUse="personal"
							login
							disabled
							on:success={contactLoginSuccess}
							on:error={handleContactError}
						/>
					</div>
				</div>
			{:else if recovery.slug === 'phone'}
				<div class="relative h-auto btn-border rounded-md hover-none px-4">
					<button class="h-16 w-full flex items-center justify-start">
						<PhoneIcon size="lg" />
						<div
							class="flex flex-col {$locale && $locale.startsWith('ar')
								? 'text-right mr-4'
								: 'text-left ml-4'}"
						>
							<span>
								{$_('Continue with Phone')}
							</span>
							<span>{recovery.login_hint}</span>
						</div>
					</button>
					<div class="pb-4 pt-1">
						<VerifyPhone
							phone={recovery.login_hint}
							login
							accountToUse="personal"
							disabled
							on:success={contactLoginSuccess}
							on:error={handleContactError}
						/>
					</div>
				</div>
			{:else if recovery.slug === 'ethereum'}
				{#if window.ethereum}
					<div class="relative h-auto btn-border rounded-md hover-none p-2 space-y-2">
						<span class="mb-3 block text-left"
							>{recovery.wallet?.name || 'Ethereum'}
							{#if recovery.wallet?.display}
								<span use:tippy={{ content: recovery.login_hint, placement: 'top' }}>
									({recovery.wallet?.display})
								</span>
							{/if}
						</span>
						<LoginProvider
							on:ethereum={() => continueWithEthereumExtension({ info: recovery })}
							provider={recovery}
							hideusername
							prefix="Continue with"
						/>
						<!-- Note: Uncomment to show WalletConnect -->
						<!-- <button
							class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
							on:click={() => continueWithWalletConnect({info: recovery})}
						>
							<div class="flex items-center gap-x-4">
								<img
									src="https://cdn.hello.coop/images/walletconnect.svg"
									alt="WalletConnect"
									class="w-4.5 max-h-[18px]"
								/>
								<span class="block text-left">
									{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
								</span>
							</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
							</svg>
						</button> -->
					</div>
				{/if}
			{:else}
				<LoginProvider
					login={true}
					on:click={() =>
						continueWithProvider({
							slug: recovery.slug,
							body: {
								accountToUse: 'personal',
								login_hint: recovery.login_hint
							},
							server: recovery.slug === 'mastodon' ? recovery.mastodonServer : null
						})}
					provider={recovery}
					prefix="Continue with"
				/>
			{/if}
		{/each}
	</div>
</section>
