<script>
	export let heading = '';
	export let logo = '';
	export let userName = '';
</script>

<section
	data-test="loggedin-provider-container"
	class="bg-[#DCDCDC] dark:bg-[#505050] -mx-2 px-2 pt-2 pb-3 rounded-md mb-6"
>
	<span class="block text-sm font-medium text-left">{heading}</span>
	<div
		class="flex flex-col justify-center items-start mt-2 px-2 rounded-md text-charcoal dark:text-[#D4D4D4]"
	>
		<div class="inline-flex items-center gap-x-2.5 mt-1">
			<span class="inline-flex items-center justify-center rounded-sm">
				<span class="managed-rounded-square-sm -ml-0">
					<img src={logo} alt="logo" class="w-4.5 max-h-[18px] object-contain" />
				</span>
			</span>
			<span>
				{userName}
			</span>
		</div>
	</div>
</section>
